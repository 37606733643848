import rmSpaces from 'components/reusable/Functions/rmSpaces'
import styled from 'styled-components'
import useScreenType from 'components/reusable/Hooks/useScreenType'
import { TKayakingRoute } from 'components/ProjectSpecificComponents/Stores/GlobalStore'
import { getRouteLength, ROUTE, scrollToTop } from 'utils/utils'
import { useNavigate } from 'react-router-dom'

type TCardsProps = {
  card: TKayakingRoute
}

const RouteCard = ({ card }: TCardsProps) => {
  const { lengthFrom, lengthTo, label, cardImgUrl, difficulty } = card
  const { isMobile } = useScreenType()
  const navigate = useNavigate()

  const onCardClick = () => {
    scrollToTop()
    navigate(`${ROUTE.toCard}/${rmSpaces(label)}`)
  }

  return (
    <RouteCardContainer
      className='main_image element-hover'
      cardImgUrl={cardImgUrl}
      isMobile={isMobile}
      onClick={onCardClick}
    >
      <RouteLength isMobile={isMobile} style={{ background: `var(--${difficulty}` }}>
        {getRouteLength(lengthFrom, lengthTo)}
      </RouteLength>
      <RouteName isMobile={isMobile}>{label}</RouteName>
    </RouteCardContainer>
  )
}

export default RouteCard

const RouteCardContainer = styled.div<{
  cardImgUrl: string
  isMobile: boolean
}>`
  border-radius: 22px;
  margin-right: ${({ isMobile }) => (isMobile ? '20px' : '35px')};
  position: relative;
  max-width: 280px;
  max-height: 500px;
  background-color: '#161616';
  box-shadow: inset 0px -50px 500px 2px hsla(0, 0%, 0%, 0.5);
  background-image: ${({ cardImgUrl }) => `url(${cardImgUrl})`};
  min-width: ${({ isMobile }) => (isMobile ? '140px' : '280px')};
  min-height: ${({ isMobile }) => (isMobile ? '250px' : '500px')};
`

const RouteLength = styled.h2<{ isMobile: boolean }>`
  position: relative;
  top: ${({ isMobile }) => (isMobile ? '10px' : '20px')};
  left: ${({ isMobile }) => (isMobile ? '10px' : '20px')};
  width: fit-content;
  padding: 4px 17px;
  border-radius: 20px;
  color: white;
  font-size: ${({ isMobile }) => (isMobile ? '18px' : '20px')};
  font-weight: ${({ isMobile }) => (isMobile ? '400' : '600')};
`

const RouteName = styled.h2<{ isMobile: boolean }>`
  position: absolute;
  bottom: ${({ isMobile }) => (isMobile ? '20px' : '50px')};
  left: ${({ isMobile }) => (isMobile ? '10px' : '20px')};
  font-weight: 600;
  color: #fff;
  line-height: 1.2;
  text-shadow: 0px 0px 15px 2px #000;
  font-size: ${({ isMobile }) => (isMobile ? '22px' : '')};
`
